<template>
  <v-container>
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
      :hideCompanyChip="true"
      v-if="!fetchinData"
    />

    <v-row v-if="!fetchinData">
      <v-col>
        <v-card tile>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{ $t("phrases.PersonalData") }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td>{{ $t("phrases.Firstname") }}</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.firstname"
                      itemName="firstname"
                      :route="patchRoute + userUuid"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="text"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("phrases.Lastname") }}</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.lastname"
                      itemName="lastname"
                      :route="patchRoute + userUuid"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="text"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>
                <tr>
                  <td>{{ $t("phrases.Email") }}</td>
                  <td>
                    <UserEmailPatcher
                      :itemValue="data.email"
                      itemName="email"
                      :userUuid="userUuid"
                      :permissionsWrite="writePerm"
                      inputElement="input"
                      inputType="email"
                      :shownValue="data.email"
                      @emit="mutateData"
                    ></UserEmailPatcher>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>

          <v-card-actions v-if="data.hasAccessData === '0'">
            <v-btn
              depressed
              color="primary"
              :disabled="hasNoEmail()"
              @click="sendAccessData()"
              :loading="updatingData"
            >
              {{ $t("plugins.users.sendAccessData") }}
            </v-btn>
          </v-card-actions>

          <v-card-actions v-else>
            <v-chip class="ma-2" color="green" text-color="white">
              {{ $t("plugins.users.accessDataSended") }}
            </v-chip>
          </v-card-actions>
        </v-card>
      </v-col>

      <v-col>
        <v-card tile>
          <v-toolbar color="primary" dark>
            <v-toolbar-title>{{
              $t("phrases.UserPermissions")
            }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <table class="default-table">
              <tbody>
                <tr>
                  <td>{{ $t("plugins.users.UserType") }}</td>
                  <td>
                    <DataPatcher
                      :itemValue="data.usersType"
                      itemName="usersType"
                      :route="patchRoute + userUuid"
                      :permissionsWrite="writePerm"
                      inputElement="select"
                      inputType="text"
                      :selectOptions="userTypes"
                      itemValueKey="id"
                      itemTextKey="name"
                      :shownValue="whatUserType(data.usersType)"
                      @emit="mutateData"
                    ></DataPatcher>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="data.usersType !== '1'">
        <UsersCompanies :userUuid="userUuid" />
      </v-col>

      <v-col v-if="data.usersType !== '1'">
        <UsersPlugins :userUuid="userUuid" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import PageHeader from "@/components/ui/PageHeader";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog";
import DataPatcher from "@/components/ui/DataPatcher";
import UserEmailPatcher from "@/components/manager/users/UserEmailPatcher";
import UsersCompanies from "@/components/manager/users/UsersCompanies";
import UsersPlugins from "@/components/manager/users/UsersPlugins";
export default {
  name: "User",

  components: {
    PageHeader,
    AreYouSureDialog,
    DataPatcher,
    UsersCompanies,
    UsersPlugins,
    UserEmailPatcher,
  },

  data() {
    return {
      userUuid: this.$route.params.id,
      pageTitle: null,
      pageDescription: null,
      fetchinData: false, // should be true when fetching data
      updatingData: false,
      legalForms: [],
      businessCategories: [],
      auth: [],
      data: [],
      userTypes: [],
      componentKey: 0,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      itemToDelete: null,
      typeToDelete: null,
      patchRoute: `/manager/users/`,
    };
  },

  props: [],

  created: function () {
    this.getData();
  },

  methods: {
    hasNoEmail() {
      if (this.data.email === "" || this.data.email == null) {
        return true;
      } else {
        return false;
      }
    },

    whatUserType(id) {
      // find usertype name in userTypes
      let name = null;
      this.userTypes.forEach((type) => {
        if (type.id === id) {
          name = type.name;
        }
      });
      return name;
    },

    async sendAccessData() {
      this.updatingData = true;
      let response = await this.post(
        `/manager/users/${this.userUuid}/accessdata`
      );

      if (response.status === 200) {
        this.data = response.data.data[0];
      }

      this.updatingData = false;
    },

    async getUserTypes() {
      let response = await this.get(`/manager/usertypes`);
      if (response.status === 200) {
        this.userTypes = response.data.data;
      }

      this.fetchinData = false;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(`/manager/users/${this.userUuid}`);

      if (response.status === 200) {
        this.data = response.data.data[0];
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
      }

      this.getUserTypes();
    },

    mutateData(value) {
      this.data[value.name] = value.value; // mutate
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
