<template>
  <v-card tile>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>{{ $t("plugins.users.usersPlugins") }}</v-toolbar-title>
    </v-toolbar>

    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        :label="$t('phrases.Search')"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-card-text>
      <v-data-table
        v-model="usersPlugins"
        :headers="headers"
        :items="plugins"
        :single-select="false"
        item-key="id"
        show-select
        checkbox-color="green"
        class="elevation-1"
        @item-selected="selection"
        :search="search"
        :loading="fetchinData"
      >
        <template v-slot:[`header.data-table-select`]> </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import store from "@/store";
import api from "@/mixins/api";
import helpers from "@/mixins/helpers";
import DataPatcher from "@/components/ui/DataPatcher";

export default {
  name: "UsersPlugins",

  components: {
    DataPatcher,
  },

  data() {
    return {
      search: null,
      fetchinData: false,
      updatingData: false,
      auth: [],
      usersPlugins: [],
      plugins: [],
      componentKey: 0,
      writePerm: null,
      readPerm: null,
      deletePerm: null,
      masterPerm: null,
      headers: [{ text: this.$t("phrases.Name"), value: "name" }],
    };
  },

  props: ["userUuid"],

  created: function () {
    this.getData();
  },

  methods: {
    selection() {
      /* warum auch immer wird getriggert, bevor usersPlugins mutiert wurde.
      Deswegen warte ich 100ms */
      const that = this;
      setTimeout(function () {
        that.updateSelection();
      }, 100);
    },

    async updateSelection() {
      this.fetchinData = true;
      let response = await this.put(
        `/manager/users/${this.userUuid}/plugins`,
        this.usersPlugins,
        "json"
      );

      if (response.status === 200) {
        this.usersPlugins = response.data.data; // mutate

        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.UserpluginsUpdated"
        );
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }

      this.fetchinData = false;
    },

    async getusersPlugins() {
      this.fetchinData = true;
      let response = await this.get(`/manager/users/${this.userUuid}/plugins`);

      if (response.status === 200) {
        this.usersPlugins = response.data.data;
      }

      this.fetchinData = false;
    },

    async getData() {
      this.fetchinData = true;
      let response = await this.get(`/manager/plugins`);

      if (response.status === 200) {
        this.plugins = response.data.data;
        this.auth = response.data.auth;
        this.writePerm = this.auth.permissions.write;
        this.deletePerm = this.auth.permissions.delete;
        this.readPerm = this.auth.permissions.read;
        this.masterPerm = this.auth.permissions.master;
      }

      this.getusersPlugins();
    },
  },

  computed: {},

  mixins: [api, helpers],
};
</script>
