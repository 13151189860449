<template>
  <div>
    <v-container v-if="!edit">
      <v-row no-gutters>
        <v-col cols="12" class="d-flex pr-2">
          <v-row>
            <v-col cols="12">
              <span>{{ shownValue }}</span>

              <v-btn
                fab
                elevation="0"
                x-small
                color="gray lighten-4"
                class="ml-2"
                :disabled="disabledByPermission(permissionsWrite)"
                @click="editItem()"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <v-row no-gutters>
        <v-col cols="10" class="pr-2 d-flex">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              class="align-self-center p0 m0"
              v-model="currentValue"
              :type="inputType"
              :rules="[rules.required, rules.email]"
              full-width
              dense
              required
              @input="$refs.form.validate(currentValue)"
            ></v-text-field>
          </v-form>
        </v-col>

        <v-col cols="2" class="d-flex justify-end">
          <v-btn
            class="mx-1 white--text"
            v-if="valueWasEdited"
            fab
            x-small
            color="primary"
            elevation="0"
            :loading="preloader"
            :disabled="!valid"
            @click="safeItem()"
          >
            <v-icon>mdi-content-save</v-icon>
          </v-btn>

          <v-btn
            class="mx-1 white--text"
            fab
            x-small
            color="secondary"
            elevation="0"
            :loading="preloader"
            @click="abortEditing()"
          >
            <v-icon>mdi-cancel</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import api from "@/mixins/api";
export default {
  name: "UserEmailPatcher",

  components: {},

  props: [
    "userUuid",
    "itemValue",
    "itemName",
    "inputElement",
    "inputType",
    "itemId",
    "switchLabel",
    "permissionsWrite",
    "counter",
    "itemValueKey",
    "itemTextKey",
    "shownValue",
  ],

  data() {
    return {
      valid: true,
      route: `/manager/users/${this.userUuid}/email/`,
      valueWasEdited: false,
      itemTextUsed: this.itemTextKey,
      itemValueUsed: this.itemValueKey,
      editorComponentKey: 0,
      preloader: false,
      edit: false,
      currentValue: this.itemValue,
      currentName: this.itemName,
      currentType: this.inputElement,
      rules: {
        required: (value) => !!value || this.$t("phrases.RequiredField"),
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || this.$t("phrases.emailNotValid");
        },
      },
    };
  },

  created: async function () {
    /* get needed data (eg for selections) via api to ref Var */

    if (this.itemValue === "" || !this.itemValue) {
      this.itemValueUsed = "value";
    }

    if (this.itemText === "" || !this.itemText) {
      this.itemTextUsed = "name";
    }
  },

  watch: {
    // Vorgang: Sobald das Value editiert wurde UND ungleich des Ausgangswertes (prop) ist, kann gespeichert werden (wird der Safe-Button angezeigt).
    currentValue: function () {
      if (this.itemValue !== this.currentValue) {
        this.valueWasEdited = true;
      } else {
        this.valueWasEdited = false;
      }
    },
  },

  methods: {
    disabledByPermission(value) {
      if (value !== "1" && this.masterPerm !== "1") {
        return true;
      } else {
        return false;
      }
    },

    editItem() {
      this.edit = true;
    },

    blurItem() {
      this.edit = false;
      this.currentValue = this.itemValue;
    },

    async safeItem() {
      const params = new URLSearchParams();
      params.append(this.itemName, this.currentValue);

      // PATCH NORMAL
      this.preloader = true;
      let response = await this.patch(
        `${this.route}${this.currentValue}`,
        params
      );
      this.preloader = false;

      if (response.status === 200) {
        this.$emit("emit", {
          action: "itemPatched",
          name: this.itemName,
          value: this.currentValue,
          response: response,
        }); // rückgabe an parent

        this.edit = false;
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = this.$t(
          "phrases.itemUpdatedMessage"
        );
        this.$root.snackbarGlobal.errorMessage = null;
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      } else {
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Fehler";
        this.$root.snackbarGlobal.errorMessage = this.$t(
          "phrases.emailNotAvailable"
        );

        this.$root.snackbarGlobal.snackbarColor = "red";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },

    abortEditing() {
      this.edit = false;
      this.currentValue = this.itemValue;
      this.editorComponentKey += 1;
    },
  },

  mixins: [api],
};
</script>
